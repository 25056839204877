import { Button as HeadlessButton } from "@headlessui/react";
import clsx from "clsx";

export interface ButtonProps {
  onClick: () => void;
  content: any;
  className?: string;
}
export function Button(props: ButtonProps) {
  const { content, onClick, className } = props;

  return (
    <HeadlessButton
      className={clsx(
        "rounded-md bg-black/20 px-12 text-sm font-medium text-white focus:outline-none data-[hover]:bg-black/30 data-[focus]:outline-1 data-[focus]:outline-white",
        className || "",
      )}
      onClick={onClick}
    >
      <div className="h-12 flex justify-center items-center ">{content}</div>
    </HeadlessButton>
  );
}
