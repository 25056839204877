import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut as googleSignOut,
  OAuthProvider,
} from "firebase/auth";
import _ from "lodash";
import { useCookies } from "react-cookie";

import { UserProfile } from "../utils/interfaces";
import { getUserInfo } from "../api";
import { config } from "../utils/config";

export interface SessionContextProps {
  token: string;
  profile?: UserProfile;
  setProfile: (profile?: UserProfile) => void;
  fetchingUserInfo: boolean;
  googleSignIn: () => Promise<void>;
  appleSignIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

export const SessionContext = createContext<SessionContextProps>({
  token: "",
  setProfile: _.noop,
  fetchingUserInfo: true,
  googleSignIn: async () => {},
  appleSignIn: async () => {},
  signOut: async () => {},
});

const firebaseConfig = JSON.parse(atob(config.firebase));

export const SessionContextWrapper = ({ children }: any) => {
  //  ---- STATES ----
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [token, setToken] = useState<string>("");
  const [profile, setProfile] = useState<UserProfile>();
  const [fetchingUserInfo, setFetchingUserInfo] = useState(true);

  //  ---- REFS ----
  const app = useRef(initializeApp(firebaseConfig));
  const auth = useRef(getAuth()).current;
  const googleProvider = useRef<GoogleAuthProvider>(new GoogleAuthProvider()).current;
  const appleProvider = useRef(new OAuthProvider("apple.com")).current;

  //  ---- CALLBACKS ----
  const configureFirebaseLocales = useCallback(() => {
    auth.languageCode = "vi";
    appleProvider.setCustomParameters({
      locale: "vi",
    });
  }, []);

  const appleSignIn = useCallback(async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const user = result.user as any;
      setToken(user.accessToken);
      setCookie("token", user.accessToken);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log({ errorCode, errorMessage });
    }
  }, []);

  const googleSignIn = useCallback(async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user as any;
      setToken(user.accessToken);
      setCookie("token", user.accessToken);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log({ errorCode, errorMessage });
    }
  }, []);

  const signOut = useCallback(async () => {
    setProfile(undefined);
    removeCookie("token");
    await googleSignOut(auth);
    setToken("");
  }, [token]);

  const fetchUserInfo = useCallback(async (token: string) => {
    setFetchingUserInfo(true);
    const response = await getUserInfo(token);
    setProfile(response.profile);

    setFetchingUserInfo(false);
  }, []);

  //  ---- USEEFFECTS ----
  useEffect(() => {
    setToken(cookies["token"] || "");
    if (!cookies["token"]) {
      setFetchingUserInfo(false);
    }
    configureFirebaseLocales();
  }, []);

  useEffect(() => {
    if (token) {
      fetchUserInfo(token);
    }
  }, [token]);

  const values = useMemo(() => {
    return {
      token,
      profile,
      setProfile,
      fetchingUserInfo,
      googleSignIn,
      appleSignIn,
      signOut,
    };
  }, [token, profile, setProfile, fetchingUserInfo, googleSignIn, appleSignIn, signOut]);

  return (
    <SessionContext.Provider value={values}>
      <>{children}</>
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);
