import React, { useCallback, useContext, useState, useEffect } from "react";
import { Description, Field, Fieldset, Input, Label, Legend, Select } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import { ChevronDownIcon, CircleStackIcon } from "@heroicons/react/20/solid";
import { LoginDialog, BaseDialog, Button } from "./components";
import clsx from "clsx";
import { SessionContext } from "./context/SessionContext";
import { createCheckout } from "./api";

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [amount, setAmount] = useState(10);
  const [isCreatingCheckout, setIsCreatingCheckout] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { token, profile, signOut } = useContext(SessionContext);

  const orderStatus = searchParams.get("status");

  useEffect(() => {
    if (orderStatus === "CANCELLED") {
      setCancelDialogVisible(true);
    } else if (orderStatus === "PAID") {
      setSuccessDialogVisible(true);
    }
    setTimeout(() => {
      setInitialized(true);
    }, 500);
  }, []);

  const callCreateCheckout = useCallback(async () => {
    if (Date.now() < 1726440801307) {
      return;
    }
    if (amount <= 0 || isCreatingCheckout) {
      return;
    }
    setIsCreatingCheckout(true);
    const result = await createCheckout(amount, token);
    window.location.href = result.payment.checkoutUrl;
    setAlertDialogVisible(false);
    setIsCreatingCheckout(false);
  }, [amount, token, isCreatingCheckout]);
  return (
    <div id="payment" className="max-w-lg px-4 items-center flex align-center">
      {!initialized ? (
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 backdrop-blur-2xl" />
        </div>
      ) : (
        <></>
      )}

      <Fieldset className="space-y-6 rounded-xl bg-white/5 p-6 sm:p-10">
        <Legend className="text-base/7 font-semibold text-white">Nạp Spoon</Legend>
        <Field className="flex justify-start w-full">
          <img
            className="object-cover h-16 w-16 rounded-full mr-4"
            src={profile?.avatar?.cdnUrl || null}
          />
          <div className="flex flex-col my-1">
            <Label className="text-md font-medium text-white">
              {profile?.displayName || "..."}
            </Label>
            <div className="flex flex-row items-center">
              <p className="text-sm font-small text-amber-300 mr-1  ">
                {profile?.creditCount || 0}
              </p>{" "}
              <CircleStackIcon height={12} width={12} color="#FCD34D" />
            </div>
            {token ? (
              <p className="text-xs font-xs text-gray-300 mr-1 underline" onClick={() => signOut()}>
                Đăng xuất
              </p>
            ) : (
              <></>
            )}
          </div>
        </Field>
        <Field>
          <Label className="text-sm/6 font-medium text-white">Số lượng</Label>
          <Input
            className={clsx(
              "mt-3 block w-full rounded-lg border-gray-300 bg-white/5 py-1.5 px-3 text-sm/6 text-white",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
              (amount && amount >= 1) ||
                "invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500",
            )}
            pattern="[0-9]"
            required
            defaultValue={amount}
            onChange={(event) => {
              const value = parseInt(event.target.value);
              setAmount(Number.isNaN(value) ? 0 : value);
            }}
          />
        </Field>
        <Field>
          <Label className="text-sm/6 font-medium text-white">Phương thức thanh toán</Label>
          <Description className="text-sm/6 text-white/50">
            Hãy chọn phương thức thanh toán bạn mong muốn
          </Description>
          <div className="relative">
            <Select
              className={clsx(
                "mt-3 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white",
                "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
                // Make the text of each option black on Windows
                "*:text-black",
              )}
            >
              <option>Chuyển khoản</option>
            </Select>
            <ChevronDownIcon
              className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
              aria-hidden="true"
            />
          </div>
        </Field>
        <Field className="w-full flex justify-center">
          <Button onClick={() => setAlertDialogVisible(true)} content={<p>Nạp</p>} />
        </Field>
        <BaseDialog
          isOpen={cancelDialogVisible}
          title={"Thanh toán bị huỷ"}
          description={"Bạn đã huỷ thanh toán"}
          buttons={[
            {
              text: "Đóng",
              onClick: () => {
                setSearchParams([]);
                setCancelDialogVisible(false);
              },
            },
          ]}
          close={() => setCancelDialogVisible(false)}
        />
        <BaseDialog
          isOpen={successDialogVisible}
          title={"Thanh toán thành công"}
          description={"Bạn đã nạp spoon thành công"}
          buttons={[
            {
              text: "Đóng",
              onClick: () => {
                setSearchParams([]);
                setSuccessDialogVisible(false);
              },
            },
          ]}
          close={() => setSuccessDialogVisible(false)}
        />
        <BaseDialog
          isOpen={alertDialogVisible}
          title={"Lưu ý!"}
          description={
            Date.now() < 1726440801307
              ? "Hệ thống đang bảo trì, vui lòng quay lại vào 6h sáng"
              : "Lệnh nạp này chỉ dùng được 1 lần, ở lần nạp tiếp theo bạn cần tạo lệnh và sử dụng QR mới"
          }
          buttons={[
            {
              text: "Tiếp tục thanh toán",
              onClick: callCreateCheckout,
              loading: isCreatingCheckout,
            },
          ]}
          close={() => setAlertDialogVisible(false)}
        />
        <LoginDialog />
      </Fieldset>
    </div>
  );
}
