import axios from "axios";
import { config } from "../utils/config";
import { CreateCheckoutResponse, UserProfile } from "../utils/interfaces";

async function baseRequest(method: string, token: string, input: any, mappings?: any) {
  const data = JSON.stringify({
    jsonrpc: "2.0",
    method,
    id: "1",
    input: input,
    ...(mappings ? { mappings } : {}),
  });
  const options = {
    method: "post",
    url: `${config.serverUrl}/jsonrpc`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  const response = await axios.request(options);
  return response.data.result;
}

export async function createCheckout(
  amount: number,
  token: string,
): Promise<CreateCheckoutResponse> {
  const response = await baseRequest("createCheckout", token, {
    amount,
  });
  return response;
}

export async function getUserInfo(token: string): Promise<{ profile: UserProfile }> {
  const response = await baseRequest("getUserInfo", token, {}, { profile: { avatar: 1 } });
  return response;
}

export async function getListBank() {
  try {
    const res = await axios({
      method: "GET",
      url: `https://api.vietqr.io/v2/banks`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}
