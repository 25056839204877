export function getEnv<T = any>(key: string, defaultValue?: T): T {
  const value = process.env[key];
  if (value !== undefined) {
    return value as T;
  }
  if (defaultValue !== undefined) {
    return defaultValue;
  }
  throw new Error(`Missing environment variable: ${key}`);
}

export interface AppConfig {
  environment: "development" | "production";
  serverUrl: string;
  testToken: string;
  firebase: string;
}

export function readConfig(): AppConfig {
  return {
    environment: getEnv("NODE_ENV", "development"),
    serverUrl: getEnv("REACT_APP_SERVER_URL"),
    testToken: getEnv("REACT_APP_TEST_TOKEN"),
    firebase: getEnv("REACT_APP_FIREBASE_SERVICE_ACCOUNT_BASE64"),
  };
}

export const config = readConfig();
